<template>
  <div class="intelligence-dashboard">
    <div class="intelligence-dashboard__header">
      <div class="title">
        <div class="heading">
          <i class="icon icon-collaborate"></i>
          <h2>News SuperIntelligence</h2>
        </div>
        <div class="show-result">
          <h4>Showing Results for <span> "Wildlife in Turkey"</span></h4>
        </div>
      </div>

      <!-- <div class="links">
        <li
          class="nav-link"
          @click="selectLink('social')"
          :class="{ active: isSelected('social') }"
        >
          Social News
        </li>
        <li
          class="nav-link"
          @click="selectLink('industry')"
          :class="{ active: isSelected('industry') }"
        >
          Industry Insights
        </li>
        <li
          class="nav-link"
          @click="selectLink('scholar')"
          :class="{ active: isSelected('scholar') }"
        >
          Scholars
        </li>
      </div> -->
    </div>

    <a-collapse
      expandIconPosition="right"
      class="intelligence-neural-network"
    >
      <a-collapse-panel key="1" header="News SuperIntelligence Neural Network">
         <heat-map></heat-map>
      </a-collapse-panel>
    </a-collapse>
    <div class="card-collapse">
      <a-row :gutter="[16, 16]" type="flex">
        <a-col :xs="24" :sm="24" :md="12" :lg="8">
          <div class="section">
            <div class="cards" ref="social-news-section">
              <div class="case-study-card"><research-article-card></research-article-card></div>
              <div class="case-study-card"><research-article-card></research-article-card></div>
              <div class="case-study-card"><research-article-card></research-article-card></div>
              <div class="case-study-card"><research-article-card></research-article-card></div>
              <div class="case-study-card"><research-article-card></research-article-card></div>
              <div class="case-study-card"><research-article-card></research-article-card></div>
              <div class="case-study-card"><research-article-card></research-article-card></div>
              <div class="case-study-card"><research-article-card></research-article-card></div>
              <div class="case-study-card"><research-article-card></research-article-card></div>
            </div>
            <div class="card-collapse__header">
              <i
                class="icon icon-forward"
                @click="toggleSection('social-news-section')"
              ></i>
              <h2>Social<span>News</span></h2>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="12" :lg="8">
          <div class="section">
            <div class="cards" ref="industry-insights-section">
              <div class="case-study-card"><research-article-card></research-article-card></div>
              <div class="case-study-card"><research-article-card></research-article-card></div>
              <div class="case-study-card"><research-article-card></research-article-card></div>
              <div class="case-study-card"><research-article-card></research-article-card></div>
              <div class="case-study-card"><research-article-card></research-article-card></div>
            </div>
            <div class="card-collapse__header">
              <i
                class="icon icon-forward"
                @click="toggleSection('industry-insights-section')"
              ></i>
              <h2>Industry<span>Insights</span></h2>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="12" :lg="8">
          <div class="section">
            <div class="cards" ref="scholars-section">
              <div class="case-study-card"><research-article-card></research-article-card></div>
              <div class="case-study-card"><research-article-card></research-article-card></div>
              <div class="case-study-card"><research-article-card></research-article-card></div>
              <div class="case-study-card"><research-article-card></research-article-card></div>
              <div class="case-study-card"><research-article-card></research-article-card></div>
            </div>
            <div class="card-collapse__header">
              <i
                class="icon icon-forward"
                @click="toggleSection('scholars-section')"
              ></i>
              <h2>Scholars</h2>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>

    <!-- <a-row :gutter="[16, 16]" class="maps">
      <a-col :xs="24" :sm="24" :md="15" :lg="15" class="maps__black"
        ><picture>
          <source
            srcset="@/assets/images/magic.webp"
            media="(max-width: 48em)"
          />
          <img srcset="@/assets/images/magic@2x.webp" /> </picture
      ></a-col>
      <a-col :xs="24" :sm="24" :md="9" :lg="9" class="maps__white">
        <img src="@/assets/images/white-map.png" alt="" />
      </a-col>
    </a-row> -->
    <!-- <a-row :gutter="[18, 18]" class="research-article">
      <a-col :xs="24" :sm="24" :md="12" :lg="8">
        <div class="research-article__card">
          <div class="card-header">
            <i class="icon icon-open_new-tab"></i>
            <div class="article-img">
              <img src="@/assets/images/daily-mail@2x.webp" alt="" />
            </div>
            <p>
              Case study Houses: The creation of the house and other things that
            </p>
          </div>
          <div class="card-body">
            <p>
              The passage is attributed to an unknown typesetter in the 15th
              century who is thought to have.
            </p>
          </div>
          <div class="card-footer">
            <p>ASIA · Fri 7:24 5:08 PM | Robert Forst, Business CNN</p>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :sm="24" :md="12" :lg="8">
        <div class="research-article__card">
          <div class="card-header">
            <i class="icon icon-open_new-tab"></i>
            <div class="article-img">
              <img src="@/assets/images/daily-mail@2x.webp" alt="" />
            </div>
            <p>
              Case study Houses: The creation of the house and other things that
            </p>
          </div>
          <div class="card-body">
            <p>
              The passage is attributed to an unknown typesetter in the 15th
              century who is thought to have.
            </p>
          </div>
          <div class="card-footer">
            <p>ASIA · Fri 7:24 5:08 PM | Robert Forst, Business CNN</p>
          </div>
        </div>
      </a-col>
      <a-col :xs="24" :sm="24" :md="12" :lg="8">
        <div class="research-article__card">
          <div class="card-header">
            <i class="icon icon-open_new-tab"></i>
            <div class="article-img">
              <img src="@/assets/images/daily-mail@2x.webp" alt="" />
            </div>
            <p>
              Case study Houses: The creation of the house and other things that
            </p>
          </div>
          <div class="card-body">
            <p>
              The passage is attributed to an unknown typesetter in the 15th
              century who is thought to have.
            </p>
          </div>
          <div class="card-footer">
            <p>ASIA · Fri 7:24 5:08 PM | Robert Forst, Business CNN</p>
          </div>
        </div>
      </a-col>
    </a-row> -->
  </div>
</template>
<script>
import ResearchArticleCard from "./ResearchArticleCard.vue";
import HeatMap from "./heatMap.vue"

export default {
  components: {
    ResearchArticleCard,
    HeatMap
  },
  props: {
    drawerVisible: Boolean,
  },
  data() {
    return {
      selectedLink: "social",
      // visible: false,
      // placement: "left",
    };
  },
  methods: {
    selectLink(link) {
      this.selectedLink = link;
    },
    isSelected(link) {
      return this.selectedLink == link;
    },
    toggleSection(section) {
      let sections = [
        "social-news-section",
        "industry-insights-section",
        "scholars-section",
      ];
      let selectedSection = this.$refs[section];
      let isClosed = selectedSection.classList.contains("closed");
      sections.forEach((val, i) => {
        if (section == val) {
          if (isClosed) {
            this.openSection(selectedSection);
          } else {
            this.closeSection(selectedSection);
          }
        } else {
          this.$refs[val].parentNode.parentNode.classList.add("ant-col-lg-8");
          this.$refs[val].parentNode.parentNode.classList.remove("ant-col-lg-2");
          this.$refs[val].parentNode.parentNode.classList.remove("ant-col-lg-14");
          this.$refs[val].classList.remove("closed");
          this.$refs[val].parentNode.querySelector(".icon-forward").style.transform = "rotate(0deg)";
          this.expandSection(val, sections, section, isClosed);
        }
      });
    },
    openSection(selectedSection) {
      selectedSection.classList.remove("closed");
      selectedSection.parentNode.parentNode.classList.remove("ant-col-lg-2");
      selectedSection.parentNode.parentNode.classList.add("ant-col-lg-8");
      selectedSection.parentNode.querySelector(".icon-forward").style.transform = "rotate(0deg)";
    },
    closeSection(selectedSection) {
      selectedSection.classList.add("closed");
      selectedSection.parentNode.parentNode.classList.add("ant-col-lg-2");
      selectedSection.parentNode.parentNode.classList.remove("ant-col-lg-8");
      selectedSection.parentNode.querySelector(".icon-forward").style.transform = "rotate(180deg)";
    },
    expandSection(val, sections, section, isClosed) {
      let previousIndex = sections.indexOf(section) - 1;
      previousIndex = previousIndex == -1 ? 2 : previousIndex;
      let previousSection = this.$refs[sections[previousIndex]];

      if (val === sections[previousIndex] && !isClosed) {
        previousSection.parentNode.parentNode.classList.remove("ant-col-lg-8");
        previousSection.parentNode.parentNode.classList.remove("ant-col-lg-2");
        previousSection.parentNode.parentNode.classList.add("ant-col-lg-14");
        previousSection.classList.remove("closed");
        previousSection.parentNode.querySelector(".icon-forward").style.transform = "rotate(0deg)";
      }
    },
  },
};
</script>
<style lang="scss">
@include reset-css;
.intelligence-dashboard {
  margin: 0 2rem;
  padding: 3rem 5rem;
  position: relative;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.93);
  &__header {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    @include respond(tab-port) {
      flex-direction: column;
      align-items: flex-start;
    }

    .title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include respond(tab-port) {
        margin-bottom: 1rem;
        justify-content: space-between;
      }
      @include respond(phone-x-small) {
        flex-direction: column;
        align-items: flex-start;
      }

      .heading {
        display: flex;
        align-items: center;

        h2 {
          font-size: 2.6rem;
          font-family: $font-secondary-bold;
          font-weight: 900;
          margin-right: 2rem;
          margin-bottom: 0;
          @include respond(laptop-small) {
            font-size: 1.8rem;
          }
        }
        .icon {
          font-size: 2.5rem;
          margin-right: 1rem;
        }
      }
      .show-result {
        @include respond(phone-x-small) {
          margin: 1rem 0;
        }
        h4 {
          font-size: 1.6rem;
          font-family: $font-secondary-bold;
          margin: 0;
          @include respond(laptop-small) {
            font-size: 1.4rem;
          }
        }
        span {
          font-family: $font-secondary;
          margin-left: 0.5rem;
        }
      }
    }
    .links {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      width: 100%;
      @include respond(tab-port) {
        justify-content: flex-start;
      }
      @include respond(phone-large) {
        justify-content: flex-start;
      }
      @include respond(phone-x-small) {
        justify-content: flex-start;
      }

      .nav-link {
        list-style: none;
        margin-right: 3rem;
        font-size: 1.6rem;
        color: $color-black;
        font-family: $font-secondary;
        position: relative;
        opacity: 0.8;
        cursor: pointer;
      }
      .active {
        font-family: $font-secondary-bold;
      }
      .active::after {
        content: "";
        width: 2.6rem;
        height: 0.4rem;
        background-color: $color-primary;
        position: absolute;
        bottom: -0.5rem;
        left: 0;
        opacity: 1;
      }
    }
  }

  .card-collapse {
    margin: 2rem 0;
    .section {
      display: flex;
    }
    .cards {
      background-color: $color-white;
      padding: 1rem;
      padding-bottom: 0;
      height: 100rem;
      overflow-y: scroll;
      overflow-x: clip;
    }
    .cards::-webkit-scrollbar-track {
      background-color: #ffffff3f;
    }
    .cards::-webkit-scrollbar {
      width: 2px;
      background-color: transparent;
      border-radius: 3rem;
    }
    .cards::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 3rem;
    }
    .ant-col-lg-14{
      .cards{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: flex-start;
        .case-study-card{
          width: calc(50% - .5rem);
        }
        
      }
    }
    .closed {
      width: 0;
      padding: 0;
    }
    &__header {
      background-color: $color-primary;
      width: 6rem;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      h2 {
        -ms-transform: rotate(90deg); /* IE 9 */
        transform: rotate(90deg);
        width: 100%;
        margin: 0;
        color: $color-white;
        font-size: 2.2rem;
        font-family: $font-primary-bold;
        span {
          margin-left: 0.8rem;
        }
      }
      .icon {
        position: absolute;
        top: 2rem;
        color: $color-white;
        font-size: 1.8rem;
        cursor: pointer;
      }
    }
  }

  .intelligence-neural-network {
    .ant-collapse-item {
      .ant-collapse-header {
        padding: 1.8rem 4rem;
        font-size: 2.2rem;
        font-family: $font-primary-bold;
        background-color: $color-primary;
        color: $color-white;
        height: 6.8rem;
        .ant-collapse-arrow {
          font-size: 2rem;
        }
      }

      .ant-collapse-content {
        padding: 3.5rem 4rem;
      }
    }

    .maps {
      display: flex;
      justify-content: space-between;
      &__black {
        img {
          width: 100%;
          height: 100%;
        }
      }
      &__white {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>